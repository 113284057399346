import { CreateLeadDto } from './lead.model';
import { LeadFilterInput } from '../services/api/lead/lead.dto.wesendapi';

export enum BankType {
  C6 = 'C6',
  FACTA = 'FACTA',
  PARANA = 'PARANA',
  CREFISA = 'CREFISA',
  V8 = 'V8',
  UY3 = 'UY3',
}

export enum BankProvider {
  bms = 'bms',
  cartos = 'cartos',
  qi = 'qi',
}

export const BankTypeName = {
  [BankType.C6]: 'C6',
  [BankType.FACTA]: 'FACTA',
  [BankType.PARANA]: 'PARANÁ',
  [BankType.CREFISA]: 'CREFISA',
  [BankType.V8]: 'V8',
  [BankType.UY3]: 'UY3',
};

export enum RobotStatusType {
  CREATNG = 'CREATNG',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  ERROR = 'ERROR',
  LOGIN_ERROR = 'LOGIN_ERROR',
  BANNED_IP = 'BANNED_IP',
  FINISHED = 'FINISHED',
}

export const RobotStatusTypeName = {
  [RobotStatusType.CREATNG]: 'CRIANDO',
  [RobotStatusType.RUNNING]: 'EXECUTANDO',
  [RobotStatusType.STOPPED]: 'PARADO',
  [RobotStatusType.ERROR]: 'ERRO',
  [RobotStatusType.LOGIN_ERROR]: 'FALHA DE LOGIN',
  [RobotStatusType.BANNED_IP]: 'IP BANIDO',
  [RobotStatusType.ERROR]: 'ERRO',
  [RobotStatusType.FINISHED]: 'FINALIZADO',
};

export enum ProductType {
  FGTS = 'FGTS',
  BOLSA_FAMILIA = 'BOLSA_FAMILIA',
}

export const ProductTypeName = {
  [ProductType.FGTS]: 'FGTS',
  [ProductType.BOLSA_FAMILIA]: 'BOLSA FAMILIA',
};

export type Robot = {
  id?: string
  type: BankType
  name: string
  // companyId: string
  createdAt?: Date | string
  updatedAt?: Date | string
  deleted?: boolean
  deletedAt?: Date | string | null
  errorAttemps?: number
  concurrency?: number
  leadFilter?: LeadFilterInput
  leadFilterId?: string | null
  login: string
  password: string
  status?: RobotStatusType
  reference?: number
  product?: ProductType
  deployName?: string | null
  simulationsLength?: number | null
  simulationsCreditLength?: number | null
  simulationsFailLength?: number | null
  simulationsWithoutPermissionLength?: number | null
  simulationsNoCreditLength?: number | null
  startedAt?: Date | string | null
  stoppedAt?: Date | string | null
  scheduled?: boolean
  startTime?: Date
  endTime?: Date
  simulationLimit?: number
  batchLength?: number
  limitedSimulation?: boolean
  retryFails?: boolean
  batchLeads: Array<CreateLeadDto>;
  tableNumber: number;
}

export interface RobotFilterInput {
  type?: BankType;
  name?: string;
  login?: string;
  status?: Array<RobotStatusType>;
  product?: ProductType;
}
