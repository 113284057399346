import { PixType } from './lead.model';

export class PixKey {
  public key: string;
  public type: PixType | null;

  constructor(key: string) {
    this.key = key?.trim();
    this.type = this.identifyPixType(this.key)
  }

  private identifyPixType(key: string): PixType {
    if (!key) return null;
    if (this.isCPF(key)) {
      this.key = this.isCPF(key);
      return PixType.CPF;
    } else if (this.isCNPJ(key)) {
      return PixType.CNPJ;
    } else if (this.isEmail(key)) {
      this.key = key.trim().toLowerCase();
      return PixType.EMAIL;
    } else if (this.isPhoneNumber(key)) {
      return PixType.PHONE;
    } else if (this.isRandomKey(key)) {
      return PixType.RANDOM;
    } else {
      return null;
    }
  }

  private isCPF(cpf: string): string {
    if (!cpf) {
      return null;
    }
    // Remover caracteres especiais e espaços em branco do CPF
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.padStart(11, '0');

    // Verificar se o CPF possui 11 dígitos
    if (cpf.length !== 11) {
      return null;
    }

    // Verificar se todos os dígitos do CPF são iguais (CPF inválido)
    if (/^(\d)\1{10}$/.test(cpf)) {
      return null;
    }

    // Calcular o primeiro dígito verificador
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.charAt(9))) {
      return null;
    }

    // Calcular o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }
    if (remainder !== parseInt(cpf.charAt(10))) {
      return null;
    }

    // Se chegou até aqui, o CPF é válido
    return cpf;
  }

  private isCNPJ(key: string): boolean {
    // Verifica se a chave tem 14 dígitos
    if (key.length !== 14) return false;

    // Verifica se a chave contém apenas números
    if (!/^\d+$/.test(key)) return false;

    // Verifica se a chave é válida usando o algoritmo de validação de CNPJ
    const sum = [5, 4, 3, 2].reduce((acc, digit, index) => {
      const value = parseInt(key[index], 10);
      return acc + value * digit;
    }, 0);
    const remainder = sum % 11;
    const checkDigit = remainder < 2 ? 0 : 11 - remainder;
    return checkDigit === parseInt(key[12], 10);
  }

  private isEmail(key: string): boolean {
    // Verifica se a chave contém o caractere '@'
    if (!key.includes('@')) return false;

    // Verifica se a chave é válida usando uma expressão regular
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(key);
  }

  private isRandomKey(key: string): boolean {
    // Verifica se a chave tem 20 dígitos
    if (key.length !== 20) return false;

    // Verifica se a chave contém apenas números e letras
    return /^[a-zA-Z0-9]+$/.test(key);
  }

  private isPhoneNumber(key: string): boolean {
    // Verifica se a chave tem 10 ou 11 dígitos
    if (key.length !== 10 && key.length !== 11) return false;

    // Verifica se a chave contém apenas números
    return /^\d+$/.test(key);
  }
}
