import { BankProvider, BankType, ProductType, RobotStatusType } from './robot.model';
import { CreateLeadDto } from './lead.model';

export type TypingRobot = {
  id?: string
  bank: BankType
  name: string
  companyId: string
  createdAt?: Date | string
  updatedAt?: Date | string
  deleted?: boolean
  deletedAt?: Date | string | null
  errorAttemps?: number
  status?: RobotStatusType
  reference?: number
  product?: ProductType
  batchLength?: number
  simulationsLength?: number | null
  simulationsCreditLength?: number | null
  simulationsSuccessLength?: number | null
  integrationSuccessLength?: number | null
  integrationFailLength?: number | null
  simulationsFailLength?: number | null
  simulationsWithoutPermissionLength?: number | null
  simulationsNoCreditLength?: number | null
  simulationsReviewLength?: number | null
  scheduled?: boolean
  startTime?: Date
  endTime?: Date
  limitedSimulation?: boolean
  simulationLimit?: number
  concurrency?: number
  deployName?: string | null
  serviceId?: string | null
  startedAt?: Date | string | null
  stoppedAt?: Date | string | null
  actualBatchId?: string | null
  tableNumber?: number | null;
  tax?: number | null;
  batchLeads: Array<CreateLeadDto>;
  bankAuthId: string;
  integrationAuthId?: string | null;
  createProposal?: boolean | null;
  retryErrorsAttempts: number;
  bankProvider: BankProvider;
  offlineSimulation: boolean | null;
  useCache: boolean | null;
}

export const TypingRobotsBanks: Array<BankType> = [BankType.FACTA, BankType.V8, BankType.UY3];
export const TypingRobotsWithTax: Array<BankType> = [BankType.FACTA, BankType.UY3];
export const TypingRobotsWithOffline: Array<BankType> = [BankType.FACTA];


